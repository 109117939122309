import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import imageUrlBuilder from '@sanity/image-url';

import Title from '../../common/title';

const builder = imageUrlBuilder({
  projectId: 'pfgbkjj1',
  dataset: 'production',
});

function urlFor(source) {
  return builder.image(source);
}

const WelcomeText = styled.p`
  font-size: 1.5rem;
  font-weight: 300;
  max-width: 640px;
  margin: 0 auto 40px;
  text-align: center;
  line-height: 1.5;
`;

const Wrap = styled.div`
  @media screen and (max-width: 768px) {
    padding-left: 32px;
    padding-right: 32px;
  }
`;

const LogoWrap = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 786px) {
    flex-direction: column;

    .gatsby-image-wrapper {
      margin-bottom: 48px !important;

      &:last-of-type {
        margin-bottom: 0 !important;
      }
    }
  }

  img {
    height: 100px;
    object-fit: contain;

    @media screen and (max-width: 768px) {
      margin-bottom: 32px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @media screen and (min-width: 768px) {
      margin: 0 40px;
    }
  }
`;

const Sponsors = () => {
  const sponsors = useStaticQuery(graphql`
    query QuerySponsors {
      allSanitySponsor {
        edges {
          node {
            _id
            title
            logo {
              asset {
                url
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Wrap>
      <Title>Our Sponsors</Title>
      <WelcomeText>
        We would like to give a huge thanks to our sponsors who help us bring
        fantastic facilities to our members.
      </WelcomeText>

      <LogoWrap>
        {sponsors.allSanitySponsor.edges.map(edge => (
          <img
            key={edge.node._id}
            src={urlFor(edge.node.logo.asset.url)
              .width(400)
              .url()}
            alt={edge.node.title}
            style={{ width: 200 }}
          />
        ))}
      </LogoWrap>
    </Wrap>
  );
};

export default Sponsors;
