import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import { rem } from 'polished';

import Layout from '../components/layout';
import SEO from '../components/seo';
import CTABlock from '../components/homepage/cta-block';
import Button from '../components/common/button';
import Container from '../components/common/container';
import Card from '../components/common/card';
import FeaturedNews from '../components/homepage/featured-news';
import Sponsors from '../components/homepage/sponsors';
import ClubNews from '../components/homepage/club-news';
import Footer from '../components/common/footer';

import { Hero } from '../components/blocks';

const Wrap = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  padding-top: ${rem('20px')};

  @media screen and (min-width: 768px) {
    padding-top: ${rem('40px')};
    justify-content: center;
    min-height: 650px;
  }

  @media screen and (min-width: 1200px) {
    max-width: 620px;
    text-align: left;
    align-items: flex-start;
  }
`;

const Title = styled.h1`
  color: white;
  font-size: 40px;
  font-weight: 800;
  line-height: 50px;
  margin-bottom: 16px;

  @media screen and (min-width: 768px) {
    font-size: 64px;
    line-height: 80px;
  }
`;

const SubTitle = styled.h2`
  color: rgba(255, 255, 255, 0.9);
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 32px;
  font-weight: 400;

  @media screen and (min-width: 768px) {
    font-size: 24px;
  }
`;

const Players = styled(Img)`
  display: none !important;
  position: absolute !important;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 45%;
  z-index: 10;

  @media screen and (min-width: 1200px) {
    display: block !important;
  }

  @media screen and (min-width: 1400px) {
    width: 50%;
    top: 55%;
  }
`;

const ButtonWrap = styled.div`
  display: flex;

  a {
    margin-right: 16px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    a {
      margin-bottom: 24px;
      margin-right: 0;
    }
  }

  a:last-of-type {
    background-color: rgb(130, 0, 0);
  }
`;

const IndexPage = ({ data }) => (
  <Layout isHomepage>
    <SEO title="Yarm Squash Club" />

    <Hero>
      <Wrap>
        <Title>Welcome to Yarm Squash Club</Title>
        <SubTitle>
          Yarm Squash Club is a friendly squash and racquetball club situated
          just a short distance away from Yarm High Street.
        </SubTitle>
        <ButtonWrap>
          <Button path="/register">Become a Member</Button>
          <Button path="https://www.probookings.co.uk/yarm" isExternal>
            Book a Court
          </Button>
        </ButtonWrap>
      </Wrap>
      <Players fluid={data.players.childImageSharp.fluid} />

      <svg viewBox="0 0 100 100" preserveAspectRatio="none">
        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop
              offset="0%"
              style={{ stopColor: 'rgb(45, 56, 97)', stopOpacity: 1 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: 'rgb(55, 69, 132)', stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>
        <polygon points="0, 100 100, -300 100, 100" fill="url(#grad1)" />
      </svg>
    </Hero>

    <Container spaced>
      <CTABlock>
        <Card to="/why-squash">
          <h2>Why Squash?</h2>
          <Img
            fluid={data.maskOne.childImageSharp.fluid}
            style={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              zIndex: 1,
            }}
          />
        </Card>
        <Card secondary to="/memberships">
          <h2>Memberships</h2>
          <Img
            fluid={data.maskTwo.childImageSharp.fluid}
            style={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              zIndex: 1,
            }}
          />
        </Card>
        <Card to="/coaching">
          <h2>Coaching</h2>
          <Img
            fluid={data.maskThree.childImageSharp.fluid}
            style={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              zIndex: 1,
            }}
          />
        </Card>
      </CTABlock>

      {data.news && data.news.edges.length >= 3 && (
        <ClubNews data={data.news.edges} />
      )}

      <Sponsors />
    </Container>

    <Footer />
  </Layout>
);

export const query = graphql`
  query {
    players: file(relativePath: { eq: "hero/players.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    maskOne: file(relativePath: { eq: "masks/mask-1.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    maskTwo: file(relativePath: { eq: "masks/mask-2.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    maskThree: file(relativePath: { eq: "masks/mask-3.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    news: allSanityPost(
      limit: 3
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          id
          title
          _rawLede
          publishedAt
          slug {
            current
          }
          featureImage {
            asset {
              _id
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
