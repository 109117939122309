import styled from 'styled-components';

const CTABlock = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  margin-bottom: 80px;
  margin-top: -140px;
  position: relative;
  z-index: 10;

  @media screen and (max-width: 768px) {
    margin-top: -56px;
    margin-bottom: 0;
    padding: 0 16px;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export default CTABlock;
