import React from 'react';
import styled from 'styled-components';

const Hero = styled.div`
  background: linear-gradient(180deg, #c63d3d 0%, #d20707 100%);
  min-height: 505px;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    padding: 16px;
    padding-bottom: 0;
  }

  @media screen and (min-width: 768px) {
    padding: 84px;
    min-height: 800px;
  }

  svg {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 45%;
    height: 100%;
    fill: #eaeaea;

    @media screen and (min-width: 1200px) {
      display: block;
    }
  }
`;

export default ({ children }) => <Hero>{children}</Hero>;
