import React from 'react';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';
import { graphql, useStaticQuery } from 'gatsby';

import Button from '../../common/button';
import Title from '../../common/title';
import serializers from '../../../serializers';

const ContentWrap = styled.div((props) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: 48,
  alignItems: 'flex-start',
  marginBottom: props.theme.space[6],
}));

const FeaturedImage = styled.img((props) => ({
  objectFit: 'cover',
  height: 450,
  width: '100%',
}));

const ButtonWrap = styled.div((props) => ({
  marginTop: props.theme.space[4],
}));

const FeaturedContent = styled.div`
  padding-bottom: ${(props) => props.theme.space[4]}px;
  padding-top: ${(props) => props.theme.space[4]}px;

  h2 {
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 16px;
  }

  p {
    font-size: 1.5rem;
    line-height: 1.5;
  }
`;

const FeaturedNews = () => {
  const data = useStaticQuery(graphql`
    query FeaturedArticle {
      sanityPost(featuredArticle: { eq: true }) {
        title
        featuredArticle
        publishedAt
        _rawLede
      }
    }
  `);

  console.log('data', data);

  return data.sanityPost && data.sanityPost.publishedAt ? (
    <div>
      <Title>{data.sanityPost.title}</Title>

      <ContentWrap>
        <div>
          <FeaturedImage src="https://images.pexels.com/photos/392018/pexels-photo-392018.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
        </div>

        <FeaturedContent>
          <BlockContent
            serializers={serializers}
            blocks={data.sanityPost._rawLede}
          />

          <ButtonWrap>
            <Button path="/">Read More</Button>
          </ButtonWrap>
        </FeaturedContent>
      </ContentWrap>
    </div>
  ) : null;
};

export default FeaturedNews;
